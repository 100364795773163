import { isEmpty } from 'lodash';
import { useSession } from 'next-auth/react';
import { useEffect } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { useNotifications } from '@/hooks/use-notifications';
import { userSettingsProvider } from '@/lib/api/providers/fluent/user-settings';
import { NEXT_AUTH_STATUSES } from '@/lib/constants';
import { DEFAULT_USER_SETTINGS, userSettingsState } from '@/state/user';
import { UserSettingsInterface } from '@/types';
import { UsersPublicTableInterface } from '@/types/database-types';
import { deepMergeReplaceNulls } from '@/utils/helpers/deep-merge';
import { logEvent } from '@/utils/logger';
import { getStoredItem, setStoredItem } from '@/utils/store/local';

export const STORAGE_KEY = 'fluentUserSettings';

interface UseUserSettingsInterface extends UserSettingsInterface {
  updateUserSettings: (newSettings: UsersPublicTableInterface['settings']) => Promise<boolean>;
  resetUserSettings: () => Promise<void>;
  isLoading: boolean;
}

const useUserSettings = (): UseUserSettingsInterface => {
  const [userSettings, setUserSettings] = useRecoilState(userSettingsState);
  const resetUserSettingsState = useResetRecoilState(userSettingsState);

  const { data: session, status, update } = useSession();
  const { addNotification } = useNotifications();

  const user = session?.user;

  useEffect(() => {
    // Only proceed if the session status has loaded to avoid flickering
    // of components that rely on user settings
    if (status === NEXT_AUTH_STATUSES.LOADING) {
      return;
    }

    if (user && !isEmpty(user.settings)) {
      // Spread the default settings first, then the user settings
      // This ensures that all settings have a value, and user settings overwrite the defaults
      const combinedSettings = deepMergeReplaceNulls(userSettings, user.settings);

      setUserSettings(combinedSettings);
    } else {
      // For non-signed in users, set the user settings from local storage.
      // Allowing them to maintain their settings across sessions
      const localStorageSettings = getStoredItem(STORAGE_KEY);
      const settings = deepMergeReplaceNulls(localStorageSettings, userSettings);
      setStoredItem(STORAGE_KEY, settings);
      setUserSettings(settings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, status]);

  const updateUserSettings = async (newSettings: UsersPublicTableInterface['settings']): Promise<boolean> => {
    const updatedSettings = deepMergeReplaceNulls(userSettings, newSettings);
    // Optimistically set the user settings, regardless of auth state
    setUserSettings(updatedSettings);

    try {
      if (!user) {
        // Update the user settings in local storage if user is not authenticated
        setStoredItem(STORAGE_KEY, updatedSettings);
      }

      if (user && status === NEXT_AUTH_STATUSES.AUTHENTICATED) {
        await userSettingsProvider.update(updatedSettings || {});

        // Update the session to keep the user settings in sync
        update();

        return true;
      }
    } catch (error) {
      logEvent.error('Update User Settings Error', { error });

      addNotification({
        heading: 'Error',
        description: 'Failed to update user settings',
        variant: 'error',
      });
    }

    return false;
  };

  const resetUserSettings = async () => {
    try {
      if (!user) {
        setStoredItem(STORAGE_KEY, DEFAULT_USER_SETTINGS);
      }

      if (user && status === NEXT_AUTH_STATUSES.AUTHENTICATED) {
        await userSettingsProvider.update(DEFAULT_USER_SETTINGS);
        update();
      }
    } catch (error) {
      logEvent.error('Reset User Settings Error', { error });
    } finally {
      resetUserSettingsState();
    }
  };

  return {
    ...userSettings,
    updateUserSettings,
    resetUserSettings,
    isLoading: status === 'loading' && !user,
  };
};

export { useUserSettings };
