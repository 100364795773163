import { isDate, mergeWith } from 'lodash';

import { NotificationChannelSettings } from '@/types';

// Will merge two objects together, replacing null values in the a with values
// from b
export const deepMergeReplaceNulls = (obj1: any, obj2: any): any => {
  if (typeof obj1 !== 'object' || obj1 === null) return obj2;
  if (typeof obj2 !== 'object' || obj2 === null) return obj1;

  const merged = { ...obj1 };

  Object.entries(obj2).forEach(([key, value]) => {
    if (value === undefined || value === null || Array.isArray(value) || isDate(value)) {
      // Override even if obj2[key] is undefined or null
      merged[key] = value;
    } else if (typeof value === 'object' && typeof obj1[key] === 'object') {
      // Recursively merge objects
      merged[key] = deepMergeReplaceNulls(obj1[key], value);
    } else {
      // Override with the value from obj2
      merged[key] = value;
    }
  });

  return merged;
};

// Will merge channel settings, always keeping false values as a priority
export const mergeNotificationChannelSettings = (
  settingsOne?: NotificationChannelSettings,
  settingsTwo?: NotificationChannelSettings,
): NotificationChannelSettings => {
  return mergeWith({}, settingsOne, settingsTwo, (a, b) => (a === false ? a : b));
};
