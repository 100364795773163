import useSWR, { KeyedMutator } from 'swr';

import { DURATION } from '@/lib/constants';
import { UsageModel } from '@/lib/models/usage/types';
import { AsyncDataInterface } from '@/types/context-types';
import { request } from '@/utils/api/request';
import { getUsageUrl } from '@/utils/helpers/api-urls';

interface UseUsageModelInterface extends AsyncDataInterface {
  usage?: UsageModel;
  mutate: KeyedMutator<UsageModel>;
}

export const useUsage = (): UseUsageModelInterface => {
  const { data, isLoading, error, mutate } = useSWR<UsageModel>(getUsageUrl, request, {
    revalidateOnFocus: false,
    errorRetryCount: 2,
    refreshInterval: DURATION.IN_MILLISECONDS.TEN_MINS,
  });

  return {
    usage: data,
    isLoading,
    error,
    mutate,
  };
};
